/* istanbul ignore file */
/* eslint-disable no-shadow */
import { dataSources } from '../../constants';
import { SET_FOOTER_DATA, SET_FETCH_ERROR } from '../../types/mutations';
import {
  GET_COPYRIGHT,
  GET_FOOTER_DATA,
  GET_FOOTER_LINKS,
  GET_LEGAL_LINKS,
  GET_SOCIAL_LINKS,
  GET_CREDIT_CARD_DATA,
  GET_DATA_SOURCE,
  GET_SIGNUP_DATA,
} from '../../types/getters';
import { useFooterFetch } from '../../composables/dumb/useNavigationFetch';
import {
  mapAemFooterLink, mapAemLegalLink, filterAemFooterLink, filterAemLegalLink,
} from '../../utils/footerUtils';
import transformEditorPopupLink from '../../utils/transformEditorPopupLink';
import { loadFooterMock } from '../../utils/footerHelpers';

export function isCached(state) {
  return !!state.data;
}

export function state() {
  return {
    data: null,
    isFetchError: false,
  };
}

export const getters = {
  [GET_DATA_SOURCE]() {
    return dataSources.aem;
  },
  [GET_FOOTER_DATA](state) {
    return state.data;
  },

  [GET_FOOTER_LINKS](_, getters) {
    const { [GET_FOOTER_DATA]: footerData } = getters;
    const footerLinks = footerData?.footer?.footerLinks;

    if (!footerLinks) return [];

    return footerLinks.filter(filterAemFooterLink).map(mapAemFooterLink);
  },

  [GET_COPYRIGHT](_, getters) {
    const { [GET_FOOTER_DATA]: footerData } = getters;
    return footerData?.footer?.copyright?.copyrightText || '';
  },

  [GET_LEGAL_LINKS](_, getters) {
    const footerData = getters[GET_FOOTER_DATA];
    const legalLinks = footerData?.footer?.legalLinks;

    return legalLinks ? legalLinks.filter(filterAemLegalLink).map(mapAemLegalLink) : [];
  },

  [GET_SOCIAL_LINKS](state) {
    const links = state.data?.footer?.socialPanel?.socialIcons?.contentlinks || [];

    return links.map(({ text, value, tracking }) => {
      const title = text.split('-')[1] || '';
      let normalizedTitle = '';
      let alt = '';

      if (title) {
        normalizedTitle = title[0].toUpperCase() + title.slice(1);

        alt = title === 'mobile' ? 'Go to our Mobile page' : `Visit us on ${normalizedTitle}`;
      }

      return {
        title: normalizedTitle,
        link: value,
        icon: text,
        alt,
        tracking,
      };
    });
  },
  [GET_CREDIT_CARD_DATA](_, getters) {
    const footerData = getters[GET_FOOTER_DATA];
    const creditCard = footerData?.footer?.socialPanel?.cardSignUp;

    if (!creditCard) return null;

    const { heading = '', body: content = '', contentlinks: actions = [] } = creditCard;

    return {
      heading,
      content,
      actions,
    };
  },
  [GET_SIGNUP_DATA](_, getters) {
    const footerData = getters[GET_FOOTER_DATA];
    const ads = footerData?.footer?.ads;

    if (!ads) return {};

    return ads.reduce((map, { type, text }) => {
      map[type] = transformEditorPopupLink(text);
      return map;
    }, {});
  },
};

export const mutations = {
  [SET_FOOTER_DATA](state, {
    data,
  }) {
    delete data.meta; // remove meta to reduce memory usage and SSR response size as we are not using it in footer
    state.data = data;
  },
  [SET_FETCH_ERROR](state, flag) {
    state.isFetchError = flag;
  },
};

export const actions = {
  async fetch({
    commit, state, rootState,
  }, force = false) {
    if (!force && isCached(state)) {
      return;
    }

    const { context, queryParams: serverQueryParams = {} } = rootState.pageData.navigation;
    const { navMock: isMockMode } = serverQueryParams;
    const { hostlink: clientBaseUrl, isMcom } = rootState.envProps;

    let data;
    let isFetchSucceed = false;
    // nice to know: the footer data is the same for mobile and desktop
    // so its not required passing deviceType
    if (!isMockMode) {
      const { fetch, result } = useFooterFetch({
        ...context, clientBaseUrl,
      });

      await fetch();

      isFetchSucceed = result.value && typeof result.value === 'object';

      if (isFetchSucceed) {
        data = result;
        commit(SET_FETCH_ERROR, false);
      }
    }

    if (!isFetchSucceed) {
      const footerMock = await loadFooterMock({
        isInternational: context.isInternational,
        isMobile: false,
        isMcom,
      });
      data = { value: footerMock };
      commit(SET_FETCH_ERROR, true);
      rootState.envProps.isNavMockFooter = true;
    }

    commit(SET_FOOTER_DATA, {
      data: data.value,
    });
  },
};
