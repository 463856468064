/* eslint-disable no-shadow */
import { normalizeRewardsData, storeLoyaltyStatusForLegacy } from '../../composables/useLoyalty';
import { useLoyaltyPointsFetch } from '../../composables/dumb/useLoyaltyPointsFetch';
import { NAV_KILLSWITCH_STORE } from '../../types/names';
import { fireLinkTag } from '../../features/analytics/tagManager';

export function state() {
  return {
    rewardsData: null,
    sourceRewardsData: null, // raw data from loyalty xapi
    initialized: false,
  };
}

export const getters = {
  getRewardsData(state) {
    return state.rewardsData || {};
  },
  getSourceRewardsData(state) {
    return state.sourceRewardsData;
  },
};

export const mutations = {
  setRewardsData(state, { sourceRewardsData, normalizedRewardsData }) {
    state.sourceRewardsData = sourceRewardsData;
    state.rewardsData = normalizedRewardsData;
  },
  setInitialized(state) {
    state.initialized = true;
  },
};

export const actions = {
  async fetchRewardsData({ commit, state, rootState }) {
    if (state.rewardsData) {
      commit('setInitialized');
      return;
    }

    const { isMcom, hostlink } = rootState.envProps;
    const { isSignedIn, guid, uid } = rootState.navUser.data;
    const { killswitches } = rootState[NAV_KILLSWITCH_STORE];

    const { fetch, result } = await useLoyaltyPointsFetch({
      isSignedIn, uid, guid, isMcom, killswitches, hostlink,
    });

    await fetch();

    if (result.value && !result.value.errors) {
      const normalizedRewardsData = normalizeRewardsData(result.value, isMcom);

      commit('setRewardsData', {
        sourceRewardsData: result.value,
        normalizedRewardsData,
      });
      storeLoyaltyStatusForLegacy(result.value, isMcom);

      // Fire loyalty analytics event only once per session
      if (!sessionStorage.navLoyaltyEventFired) {
        const payload = {
          event_name: 'loyalty call',
          link_name: 'loyalty call',
          CustomerFirstName: rootState.navUser.data.username,
          customer_loyalty_id: isMcom ? result.value.encryptedLoyaltyId : result.value.loyalty.id,
          customer_loyalty_tier: normalizedRewardsData.tierName,
          loyalty_balance: normalizedRewardsData.points.current,
          rewards_balance: normalizedRewardsData.balance,
        };

        fireLinkTag(payload);
        sessionStorage.navLoyaltyEventFired = true; // Prevent additional fires
      }
    } else {
      storeLoyaltyStatusForLegacy({}, isMcom);
    }
    commit('setInitialized');
  },
};
