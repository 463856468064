<template>
  <button
    ref="menuTarget"
    aria-label="menu"
    aria-haspopup="true"
    aria-controls="mobile-nav"
    :aria-expanded="isMenuOpened"
    class="icon-24x24 menu-icon"
    data-testid="burger-button"
    data-block-outside-click
    @click="toggleNav"
  />
</template>

<script setup>
import { useMobileNav } from '../../composables/useMobileNav';

const { menuTarget, isMenuOpened, toggleNav } = useMobileNav();
</script>

<style lang="scss" brand="mcom">
@import "../../scss/icons.mcom.scss";

.menu-icon {
  @extend .menu-large;
}
</style>

<style lang="scss" brand="bcom">
@import "../../scss/icons.bcom.scss";

.menu-icon {
  @extend .menu-small;
}
</style>
