const { getBCOMLinkPrefixBySiteMode } = require('./src/utils/getSiteMode');

const FRICTION_FREE_CATEGORY = 1132361;
const ENTRY_POINT = '0000';
const LEARN_MORE_PARAM = '_learnMore';
const BOPS_ENTRY_POINT_URL = `/shop/pickup-delivery/Upc_bops_purchasable/${ENTRY_POINT}?id=${FRICTION_FREE_CATEGORY}`;

module.exports = {
  brand: 'bcom',
  mobileTitles: {
    happeningNow: 'Happening now',
    shopByDepartment: 'Shop by department',
    shopByStore: 'Shop your store',
    moreToExplore: 'More to explore',
  },
  appLinks: [
    {
      title: 'App Store',
      link: 'https://apps.apple.com/us/app/bloomingdales-big-brown-bag/id525536985',
      img: 'appStore.svg',
    },
  ],
  getFlagUrl: (regionCode, getAssetImage) => getAssetImage(`flags/${regionCode}.png`),
  Links: {
    homepage: {
      site: {
        url: '/',
        tracking: {
          cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-TOP_BLOOMIES_ICON`,
        },
      },
    },
    bag: {
      url: '/my/bag',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-BAG-n-n`,
      },
    },
    wishlist: {
      url: '/wishlist/mylist?showDefault=true',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-my_account-_-my_wish_list`,
      },
    },
    store: {
      changeStore: {
        text: 'Change store',
      },
      pickupAndDelivery: {
        text: 'Pickup & delivery',
      },
      bookAppointment: {
        url: '/b/about-us/appointment-scheduling-in-store-services',
        text: 'Book appointment',
      },
      freeStorePickup: {
        url: BOPS_ENTRY_POINT_URL,
        text: 'Shop free store pickup',
        pos: 2,
      },
      sameDayDelivery: {
        url: `/shop/pickup-delivery/Upc_sdd_purchasable/${ENTRY_POINT}?id=${FRICTION_FREE_CATEGORY}`,
        text: 'Shop same-day delivery',
        pos: 3,
      },
      nextDayDelivery: {
        url: `/shop/pickup-delivery/Upc_sdd_purchasable/${ENTRY_POINT}?id=${FRICTION_FREE_CATEGORY}`,
        text: 'Shop next-day delivery',
        pos: 3,
      },
      learnMore: {
        url: `${BOPS_ENTRY_POINT_URL}&${LEARN_MORE_PARAM}=true`,
        text: 'Learn more',
        pos: 4,
      },
    },
  },
  minimal: {
    links: {
      default: [
        {
          name: 'Terms of Use',
          url: 'https://www.customerservice-bloomingdales.com/app/answers/detail/a_id/356',
        },
        {
          name: 'Privacy Notice',
          url: 'https://www.customerservice-bloomingdales.com/app/answers/detail/a_id/769',
        },
        {
          name: 'Interest Based Ads',
          url: 'https://www.customerservice-bloomingdales.com/app/answers/detail/a_id/357',
        },
      ],
      checkout: [
        {
          name: 'Privacy Policy',
          url: 'https://customerservice-bloomingdales.com/articles/highlights-of-bloomingdales-notice-of-privacy-practices?_ga=2.27359740.1533210581.1692643555-605321279.1691657634',
        },
        {
          name: 'Legal Notice',
          url: 'https://customerservice-bloomingdales.com/articles/bloomingdales-terms-of-use?_ga=2.27359740.1533210581.1692643555-605321279.1691657634',
        },
        {
          name: 'Interest Based Ads',
          url: 'https://customerservice-bloomingdales.com/articles/bloomingdales-and-bloomingdalescom-notice-of-privacy-practices?_ga=2.29045884.1533210581.1692643555-605321279.1691657634#interest-based-or-online-behavioral-advertising',
        },
        {
          name: 'Shipping Policy',
          url: 'https://www.customerservice-bloomingdales.com/category/shipping-delivery',
          tracking: {
            cm_sp: 'navigation-_-bottom_nav-_-shipping_%26_delivery',
            lid: 'glbbtmnav_shipping_%26_delivery-us',
          },
        },
        {
          name: 'Return Policy',
          url: 'https://www.customerservice-bloomingdales.com/category/returns',
          tracking: {
            cm_sp: 'navigation-_-bottom_nav-_-returns',
            lid: 'glbbtmnav_returns-us',
          },
        },
      ],
    },
    copyright: [
      `© ${new Date().getFullYear()} Bloomingdale's. 1000 Third Avenue New York, NY 10022`,
    ],
  },
  logoLabel: {
    site: "Bloomingdale's Home Page",
  },
  defaultStoreName: 'Find a store',
  registryLinkGroups: [
    {
      title: null,
      items: [
        {
          text: 'Find a registry',
          url: '/registry/search',
        },
        {
          text: 'Create a registry',
          url: '/registry',
        },
        {
          text: 'Wedding checklist',
          url: '/c/gift-registry/wedding/checklist/',
        },
        {
          text: 'Baby checklist',
          url: '/c/gift-registry/baby/checklist/',
        },
        {
          text: 'FAQs',
          url: 'https://customerservice-bloomingdales.com/category/wedding-registry-gifts',
        },
      ],
    },
  ],
};
