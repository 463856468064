<template>
  <section>
    <h4
      class="heavy"
      aria-label="Social Media List"
    >
      <slot />
    </h4>
    <ul class="grid-x padding-top-xs">
      <li
        v-for="item in links"
        :key="item.link"
        class="icon-24x24"
      >
        <Link
          :href="item.link"
          :aria-label="item.alt"
          :tracking="item.tracking"
          :class="item.icon"
          target="_blank"
        >
          <span class="visibility-hidden">{{ item.alt }}</span>
        </Link>
      </li>
    </ul>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Link from '../common/Link';
import { GET_SOCIAL_LINKS } from '../../types/getters';

const store = useStore();
const links = computed(() => store.getters[`footerStore/${GET_SOCIAL_LINKS}`]);
</script>

<style scoped lang="scss">
ul {
  gap: map-get($grid-margin-gutters, 'medium');
}
</style>

<style scoped lang="scss" brand="mcom">
label {
  color: $white;
}
</style>
