<template>
  <section>
    <div class="rewards-pattern grid-x">
      <div
        v-for="part in parts"
        :key="part"
        :class="['tier-part', `loyalty-${part}-color`]"
      />
    </div>
    <div class="grid-x flex-dir-column align-middle padding-top-xl">
      <div class="grid-x align-middle">
        <div class="rewards-logo">
          <Picture
            :image-url="getAssetImage('starRewards.svg')"
            :using-scene7="false"
            native-lazy
            skeleton
            alt=""
          />
        </div>
        <div
          v-if="rewards.tierName"
          class="rewards-tier"
        >
          {{ capitalizeFirstLetter(rewards.tierName) }}
        </div>
      </div>
      <h3 class="rewards-salutation margin-bottom-xxs">
        Hi, {{ username }}
      </h3>
      <div
        v-if="rewards.tierName"
        class="rewards-stats grid-x margin-bottom-s padding-top-xs"
      >
        <div class="grid-x flex-dir-column align-middle padding-right-xl">
          <div class="stat-title medium">
            Star Money
          </div>
          <h3 data-testid="rewards-net-worth">
            ${{ netWorth }}
          </h3>
          <div
            v-if="expiryMoney"
            class="small rewards-expiry-money"
          >
            ${{ expiryMoney }} expiring soon!
          </div>
        </div>

        <div class="grid-x flex-dir-column align-middle">
          <div class="stat-title medium">
            Points
          </div>
          <h3 data-testid="rewards-both-points">
            {{ bothPoints }}
          </h3>
        </div>
      </div>
      <div
        v-else
        class="grid-x flex-dir-column align-middle"
      >
        <p class="medium margin-bottom-s text-center">
          Earn points on purchases when you <br> join Star Rewards
        </p>
        <Link
          class="button nav-join-btn primary margin-bottom-xs"
          :href="Links.loyalty.default.url"
          :tracking="Links.loyalty.default.tracking"
          data-testid="join-star-rewards"
        >
          Join Star Rewards
        </Link>
      </div>

      <div class="grid-x align-middle margin-bottom-m">
        <Link
          :href="Links.loyalty.default.url"
          :tracking="Links.loyalty.default.tracking"
          class="small"
        >
          View Member Benefits
        </Link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import Picture from '@atomic-ui/picture';
import Link from '../common/Link';
import Links from '../../constants/Links';
import useLoyalty from '../../composables/useLoyalty';
import useAccount from '../../composables/useAccount';
import { capitalizeFirstLetter } from '../../utils/StringUtils';
import getAssetImage from '../../utils/getAssetImage';

const { rewards, bothPoints, expiryMoney } = useLoyalty();
const { username } = useAccount();

const netWorth = computed(() => {
  const { balance } = rewards.value;

  return balance.toFixed(2);
});

const parts = Array.from({ length: 4 }, (_, i) => i + 1);
</script>

<style lang="scss" brand="mcom">
  .rewards-logo {
    img {
      vertical-align: top;
    }
  }
</style>

<style lang="scss" brand="mcom" scoped>
  .rewards {
    &-pattern {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 10px;

      .mobile & {
        left: -20px;
        right: -20px;
      }
    }

    &-logo {
      width: 130px;
      height: 20px;
      overflow: hidden;
    }

    &-tier {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      position: relative;
      padding-left: 12.5px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 6px;
        height: 100%;
        width: 1px;
        background-color: $black;
      }
    }

    &-salutation {
      padding-top: 4px;
    }

    &-stats {
      min-width: 60%;
      justify-content: space-between;
    }

    &-expiry-money {
      color: $primary-color;
      margin-top: 4px;
    }
  }

  .tier-part {
    height: 100%;
    width: 25%;
    background-color: currentColor;
  }

  .stat {
    &-title {
      margin-bottom: 4px;
    }
  }

  .nav-join-btn {
    width: 300px;

    @include breakpoint(medium down) {
      height: 40px;
      width: 199px;
    }
  }
</style>
