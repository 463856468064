<template>
  <div class="count-bag grid-x align-center align-middle heavy-sm">
    <slot />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.count-bag {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" brand="mcom" scoped>
.count-bag {
  color: #fff;
  margin-top: 2px;
}
</style>

<style lang="scss" brand="bcom" scoped>
.count-bag {
  color: #492E19;
  margin-top: 3px;
}
</style>
