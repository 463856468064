/* eslint-disable no-shadow */
import {
  GET_HEADER_DATA,
  GET_MYACCOUNT_LINKS,
  GET_DEALS_DATA,
  GET_SECONDARY_NAV,
  GET_SEARCH_MODAL_STATE,
  GET_ATTRS,
} from '../../types/getters';
import {
  SET_HEADER_DATA,
  SET_SEARCH_MODAL_STATE,
  SET_SEARCH_VALUE,
  SET_SEARCH_BAR_ANIMATE,
  SET_SKIP_CONTENT_OFFSET,
  SET_ATTRS,
  SET_FETCH_ERROR,
  SET_SECONDARY_NAVIGATION,
} from '../../types/mutations';
import { APPLY_SEARCH_TERM } from '../../types/actions';
import { viewTypes } from '../../constants';
import { addSignedInState } from './helpers';

export function state() {
  return {
    data: {
      [viewTypes.desktop]: null,
      [viewTypes.mobile]: null,
    },
    isFetchError: false,
    attrs: {},
    isSearchModalActive: false,
    isSearchBarAnimated: false,
    permanentSigninTooltip: false,
    searchValue: '',
    skipToContentOffset: 0,
  };
}
export const getters = {
  [GET_HEADER_DATA](state, _, rootState) {
    const { viewType } = rootState.navViewTypeStore;
    return state.data[viewType];
  },
  [GET_SECONDARY_NAV](_, getters) {
    const { [GET_HEADER_DATA]: data } = getters;

    return data?.secondaryNavigation || [];
  },
  [GET_MYACCOUNT_LINKS](state, getters, rootState) {
    const { [GET_HEADER_DATA]: data } = getters;

    return addSignedInState(data?.media?.SITE_MYACCOUNT_MENU?.items?.[0]?.children || [], rootState);
  },
  [GET_DEALS_DATA](_, getters) {
    const { [GET_HEADER_DATA]: data } = getters;

    return data?.skinnyDealsBanner;
  },
  [GET_SEARCH_MODAL_STATE](state) {
    return state.isSearchModalActive;
  },
  [GET_ATTRS](state) {
    return state.attrs;
  },
};

export const mutations = {
  [SET_HEADER_DATA](state, { viewType, data }) {
    const currentData = state.data[viewType] || {};

    state.data[viewType] = {
      ...currentData,
      ...data,
      // merge meta and properties with new data instead of replacing it with new data
      meta: {
        ...(currentData.meta || {}),
        ...data.meta,
        properties: {
          ...(currentData.meta?.properties || {}),
          ...data.meta.properties,
        },
      },
      // merge media with new data instead of replacing it with new data
      media: {
        ...(currentData.media || {}),
        ...data.media,
      },
      menu: data.mobileMenu || data.menu || currentData.menu,
      desktopMenu: data.desktopMenu || currentData.desktopMenu,
      mobileMenu: null,
    };
  },
  [SET_SECONDARY_NAVIGATION](state, { data, viewType }) {
    state.data[viewType].secondaryNavigation = data;
  },
  [SET_SEARCH_MODAL_STATE](state, payload) {
    state.isSearchModalActive = payload;
  },
  [SET_ATTRS](state, payload) {
    state.attrs = payload;
  },
  [SET_SEARCH_VALUE](state, payload) {
    state.searchValue = payload;
  },
  [SET_SEARCH_BAR_ANIMATE](state, payload) {
    state.isSearchBarAnimated = payload;
  },
  [SET_SKIP_CONTENT_OFFSET](state, payload) {
    state.skipToContentOffset = payload;
  },
  [SET_FETCH_ERROR](state, flag) {
    state.isFetchError = flag;
  },
};

export const actions = {
  [APPLY_SEARCH_TERM]({ rootState, commit }) {
    const { originalSearchTerm, searchTerm, navigationType } = rootState?.pageData?.meta?.context || {};
    let committedSearchTerm = originalSearchTerm || searchTerm;

    if (navigationType === 'BROWSE' && committedSearchTerm) {
      committedSearchTerm = committedSearchTerm.replace(/-/g, ' ');
    }

    if (committedSearchTerm) {
      commit(SET_SEARCH_VALUE, committedSearchTerm);
    }
  },
};
