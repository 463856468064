/* istanbul ignore file */
const { getBCOMLinkPrefixBySiteMode } = require('../../utils/getSiteMode');

module.exports = {
  homepage: {
    default: {
      url: '/',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-TOP_BLOOMIES_ICON`,
      },
    },
    registry: {
      url: '/registry',
    },
    account: {
      url: '/myaccount/home',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-MY_ACCOUNT-n-n`,
      },
    },
  },
  account: {
    profile: {
      url: '/account/profile',
    },
    wallet: {
      url: '/account/wallet?ocwallet=true',
    },
    signIn: {
      url: '/account/signin',
    },
    signOut: {
      url: '/account/logout',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-MY_ACCOUNT-n-n`,
      },
    },
  },
  loyalty: {
    default: {
      url: '/loyallist/enrollment',
    },
    benefits: {
      url: '/loyallist/accountsummary',
    },
  },
  store: {
    locator: {
      url: 'https://www.bloomingdales.com/stores/',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-STORES_EVENTS-n-n`,
      },
    },
    scheduling: {
      url: '/b/about-us/appointment-scheduling-in-store-services',
    },
  },
  creditCard: {
    default: {
      url: '/my-credit/gateway',
    },
    apply: {
      url: '/my-credit/gateway/guest',
    },
    exclusions: {
      url: '/shop/popup?popupID=679889',
      tracking: {
        cm_sp: 'footer-_-InfoEx-_-NAD20',
      },
    },
  },
  purchases: {
    default: {
      url: '/purchases/online',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-order_history-_-my_order_status_history`,
      },
    },
  },
  promo: {
    emailSignUp: {
      url: '/signup',
    },
    exclusions: {
      url: '/shop/popup?popupID=634965',
    },
    promotions: {
      url: '/shop/coupons-sales-promotions',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-OFFERS-n-n`,
      },
    },
  },
  mobile: {
    about: {
      url: '/b/about-us/mobile-shopping-online',
    },
  },
  customerService: {
    faq: {
      url: () => 'https://customerservice-bloomingdales.com/app/answers/list/session/L3RpbWUvMTM3MDAyNjEzNy9zaWQvdjRqRE5Bcmw%3D/c/27',
    },
  },
  loyallist: {
    myLoyallist: {
      url: '/loyallist/accountsummary',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-MYLOYALLIST-VIEWMYPOINTS-n`,
      },
    },
    memberBenefits: {
      url: '/creditservice/marketing/benefits',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-My_Loyallist_splash-loyallistMemberBenefits-Member_Benefits`,
      },
    },
    myTriplePointsDays: {
      url: '/loyallist/pickyourdays',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-My_Loyallist_splash-loyallistMemberBenefits-My_Triple_Points_Days`,
      },
    },
    programOverview: {
      url: '/b/loyallist/benefits/',
      tracking: {
        cm_sp: (context) => `${getBCOMLinkPrefixBySiteMode(context)}-_-My_Loyallist_splash-loyallistMemberBenefits-My_Perks`,
      },
    },
    fAQs: {
      url: '/loyallist/faq',
      tracking: {
        cm_sp: 'my_account-_-loyallist-_-FAQs',
      },
    },
  },
};
