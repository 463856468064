<template>
  <header
    class="mobile"
    :class="{'grid-container': isMcom}"
  >
    <slot />

    <div
      class="padding-bottom-xs"
      :class="{'grid-container': !isMcom, 'pt-9': experiments.condensedHeaderEnabled, 'padding-top-xs': !experiments.condensedHeaderEnabled }"
    >
      <MobileMainRailCondensed v-if="experiments.condensedHeaderEnabled" />
      <MobileMainRail v-else />
      <Search :class="{'pt-9': experiments.condensedHeaderEnabled, 'padding-top-xs': !experiments.condensedHeaderEnabled }" />
    </div>

    <MobileMenu />
  </header>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';
import MobileMenu from './MobileMenu.common';
import MobileMainRail from './MobileMainRail.common';
import MobileMainRailCondensed from './MobileMainRailCondensed.common';

import Search from '../search/Search.common';
import { useRegistry } from '../../composables/useRegistry';
import {
  onUserInit, onKsFlagsReady, onAllPassedEventsReady, onKsFlagsFetched,
} from '../../events';
import { FETCH_PREFERRED_STORE } from '../../types/actions';
import { storeLoyaltyStatusForLegacy } from '../../composables/useLoyalty';
import useExperiments from '../../composables/useExperiments';

const store = useStore();
const experiments = useExperiments();
const { isMcom } = store.state.envProps;
const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
const { fetchRegistries } = useRegistry({ checkUpdates: true });

function fetchLoyalPoints() {
  if (isSignedIn.value) {
    store.dispatch('navLoyaltyStore/fetchRewardsData');
  } else {
    storeLoyaltyStatusForLegacy({}, isMcom);
  }
}

const fetchPreferredStore = () => {
  store.dispatch(`navPreferredStore/${FETCH_PREFERRED_STORE}`);
};

onUserInit(fetchPreferredStore);
onAllPassedEventsReady(
  [onKsFlagsFetched, onKsFlagsReady, onUserInit],
  () => {
    fetchRegistries();
    fetchLoyalPoints();
  },
);
</script>

<style lang="scss">
.pt-9 {
  padding-top: 9px;
}
</style>
