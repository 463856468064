<template>
  <div ref="targetEl">
    <slot v-if="inViewport" />
  </div>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core';
import { ref } from 'vue';

const targetEl = ref(null);
const inViewport = ref(false);

const props = defineProps({
  rootMargin: {
    type: Number,
    default: 600,
  },
});

const { stop } = useIntersectionObserver(
  targetEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      inViewport.value = isIntersecting;
      stop();
    }
  }, {
    rootMargin: `${props.rootMargin}px`,
  },
);
</script>
