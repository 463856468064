import { computed } from 'vue';
import { useStore } from 'vuex';
import { Cookie } from '@common-vue/util/src/modules/cookie';
import CacheManager from '@common-vue/util/src/modules/cacheManager';

import { HNFSR_LOYALTY_EXPIRESIN } from '../constants/common/loyaylty';

/**
 * @typedef {Object} RewardsPoints
 * @property {number} current - The current points the user has.
 * @property {number} thresholdForAward - The points threshold for the next award.
 */

/**
 * @typedef {Object} NormalizedRewardsData
 * @property {string} tierName - The normalized tier name.
 * @property {RewardsPoints} [points] - Normalized points information.
 * @property {boolean} isRewardExpiring - Indicates if any reward card is expiring soon.
 * @property {number} balance - The reward balance.
 */

const bcomMemberTexts = {
  base: 'Loyallist',
  top: 'Top of the List',
  topUnlocked: 'Top of the List Unlocked',
};

const bcomMemberIcons = {
  base: 'loyallist-small',
  top: 'loyallist-small-black',
  topUnlocked: 'loyallist-key-small',
};

export default function useLoyalty() {
  const store = useStore();
  const { isMcom } = store.state.envProps;

  /**
   * @type {import('vue').ComputedRef<NormalizedRewardsData>}
   */
  const rewards = computed(() => store.getters['navLoyaltyStore/getRewardsData']);

  const bcomMember = computed(() => bcomMemberTexts[rewards.value.tierName] || bcomMemberTexts.base);
  const bothPoints = computed(() => {
    const { points = {} } = rewards.value;
    const { current = '', thresholdForAward = '' } = points;

    if (!current && !thresholdForAward) return '';
    return `${current}/${thresholdForAward}`.replace(',', '');
  });

  const loyaltyIcon = computed(() => {
    const { tierName } = rewards.value;

    if (isMcom) {
      return tierName ? `star-rewards-${tierName}-large` : 'star-rewards-red-large';
    }
    return bcomMemberIcons[tierName] || bcomMemberIcons.base;
  });

  const isNotZeroBalance = computed(() => {
    const { balance } = rewards.value;

    return balance > 0;
  });

  const expiryMoney = computed(() => {
    const { expiringBalance, isRewardExpiring } = rewards.value;

    return isRewardExpiring && expiringBalance ? expiringBalance.toFixed(2) : null;
  });

  return {
    rewards,
    loyaltyIcon,
    bcomMember,
    bothPoints,
    expiryMoney,
    isNotZeroBalance,
  };
}

export function getBcomTierName(fullTierName = '') {
  if (fullTierName === null) {
    return '';
  }

  switch (true) {
  case fullTierName.includes('BASE'):
    return 'base';
  case fullTierName.includes('TOL'):
    return 'top';
  case fullTierName.includes('UNL'):
    return 'topUnlocked';
  default:
    return '';
  }
}

/**
 * Parse balance value to number
 *
 * @param {string} value
 * @returns {number}
 */
export function parseBalance(value) {
  if (!value) {
    return 0;
  }

  return typeof (value) === 'string' ? parseFloat(value.replace('$', '')) : value;
}

// "2,084" => 2084
function parsePoints(value) {
  return value ? Number(value.replace(/,/g, '')) : null;
}

/**
 * Normalize rewards data based on the provided parameters.
 *
 * @param {Object} data - The rewards data to be normalized.
 * @param {boolean} isMcom - is mcom brand
 * @returns {NormalizedRewardsData} Normalized rewards data.
 */
export function normalizeRewardsData(data, isMcom) {
  if (isMcom) {
    const { tierInfo: { tierName: tierNameUpperCased = '' } = {}, rewardsInfo = {} } = data;
    const tierName = tierNameUpperCased.toLowerCase();

    const {
      currentPoints = null, pointsThresholdForAward = null, anyRewardCardExpiringSoon, totalStarRewardCardsBalance, rewardCards,
    } = rewardsInfo;
    const points = {
      current: currentPoints,
      thresholdForAward: pointsThresholdForAward,
    };
    const expiringBalance = rewardCards
      ? rewardCards.reduce((acc, { rewardMoney, expirySoon }) => (expirySoon ? acc + parseBalance(rewardMoney) : acc), 0)
      : 0;

    return {
      tierName,
      points,
      isRewardExpiring: !!anyRewardCardExpiringSoon,
      balance: parseBalance(totalStarRewardCardsBalance),
      expiringBalance,
    };
  }

  // bcom
  const { loyalty = {} } = data;
  const {
    tierInfo = {}, points = {}, anyRewardCardExpiryingSoon, rewardCardBalance, rewardCards,
  } = loyalty;
  const { tierName: fullTierName } = tierInfo;
  const tierName = getBcomTierName(fullTierName);
  const { totalAvailablePoints, pointsToNextAward } = points;
  const expiringBalance = rewardCards
    ? rewardCards.reduce((acc, { rewardMoney, expirySoon }) => (expirySoon ? acc + parseBalance(rewardMoney) : acc), 0)
    : 0;

  return {
    tierName,
    balance: parseBalance(rewardCardBalance),
    points: {
      current: parsePoints(totalAvailablePoints),
      thresholdForAward: parsePoints(pointsToNextAward),
    },
    isRewardExpiring: !!anyRewardCardExpiryingSoon,
    expiringBalance,
  };
}

/**
 * Store loyalty status
 *
 * @param {Object} data - full rewards data
 * @param {Boolean} isMcom - isMcom
 */
export function storeLoyaltyStatusForLegacy(data, isMcom) {
  let result;
  let isLoyaltyUser = false;
  let srKey;

  const navLoyaltyDataUpdatedEvent = new CustomEvent('navLoyaltyDataUpdated', {
    detail: Object.keys(data).length === 0 ? null : data,
  });
  window.dispatchEvent(navLoyaltyDataUpdatedEvent);

  if (isMcom) {
    if ((data.tierInfo && typeof data.tierInfo.tierName === 'string' && typeof data.rewardsInfo === 'object') || data.starRewardsAutoEnrollEnabled) {
      result = data;
      isLoyaltyUser = true;
    }

    srKey = `hnfsr_${Cookie.get('macys_online_uid')}`;
  } else {
    if (data.loyalty?.tierInfo) {
      result = {
        ...data,
        tierInfo: data.loyalty.tierInfo, // to support backwards compatibility with backbone pages
      };
      isLoyaltyUser = true;
    }

    srKey = `hnfsr_${Cookie.get('bloomingdales_online_uid')}`;
  }
  const isLoyaltyKey = 'isLoyaltyUser';

  // we have to set these values to both storages as different pages are reading these values from different storages
  if (result) {
    CacheManager.setPersistent(isLoyaltyKey, isLoyaltyUser, HNFSR_LOYALTY_EXPIRESIN);
    CacheManager.setPersistent(srKey, result, HNFSR_LOYALTY_EXPIRESIN);
  } else {
    CacheManager.removePersistent(isLoyaltyKey);
    CacheManager.removePersistent(srKey);
  }
}
