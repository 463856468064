<template>
  <button
    class="link-rail-item grid-x align-middle"
    @click.prevent="openInternationalSlideout"
  >
    <span>Shipping To</span>
    <Flag
      v-if="mounted"
      width="16px"
      height="16px"
      class="margin-left-xxs"
    />
    <Skeleton
      v-else
      width="16px"
      height="16px"
      class="margin-left-xxs"
    />
  </button>
</template>

<script setup>
/* istanbul ignore file */
import { useMounted } from '@vueuse/core';
import Skeleton from '@atomic-ui/skeleton';
import useInternationalSlideout from '../../composables/features/useInternationalSlideout';
import Flag from '../common/Flag.common';

const mounted = useMounted();
const { openInternationalSlideout } = useInternationalSlideout();
</script>

<style lang="scss" scoped brand="mcom"></style>
