const GET_HEADER_DATA = 'getHeaderData';
const GET_FOOTER_DATA = 'getFooterData';
const GET_FOOTER_LINKS = 'getFooterLinks';
const GET_IS_DESKTOP_VIEW = 'getIsDesktopView';
const GET_IS_MOBILE_VIEW = 'getIsMobileView';
const GET_IS_HAVE_SUGGESTIONS = 'gethasSuggestions';
const GET_SECONDARY_NAV = 'getSecondaryNavigation';
const GET_MYACCOUNT_LINKS = 'getMyAccountLinks';
const GET_DEALS_DATA = 'getDealsData';
const GET_STORE_INFO = 'getStoreInfo';
const GET_STORE_OPERATION_INFO = 'getStoreOperationInfo';
const GET_FORMATTED_COUNT = 'formattedCount';
const GET_IS_MORE_THAN_ONE = 'isMoreThanOne';
const GET_IS_EMPTY = 'isEmpty';
const GET_IS_ACTIVE_OVERLAY = 'getIsActiveOverlay';
const GET_OVERLAY_NAME = 'getOverlayName';
const GET_COPYRIGHT = 'getCopyright';
const GET_LEGAL_LINKS = 'getLegalLinks';
const GET_SOCIAL_LINKS = 'getSocialLinks';
const GET_ZIP_CODE = 'getZipCode';
const GET_SDD_MESSAGE = 'getSddMessage';
const GET_STORE_NAME = 'getStoreName';
const IS_STORE_SELECTED = 'isStoreSelected';
const GET_SEARCH_MODAL_STATE = 'getSearchModalState';
const GET_ATTRS = 'getAttrs';
const IS_STORES_LOADING = 'isStoresLoading';
const SHOW_MINIMAL_SEARCH = 'showMinimalSearch';
const GET_CREDIT_CARD_DATA = 'getCreditCard';
const GET_SIGNUP_DATA = 'getSignUpData';
const GET_DATA_SOURCE = 'dataSource';

export {
  GET_HEADER_DATA,
  GET_FOOTER_DATA,
  GET_FOOTER_LINKS,
  GET_IS_DESKTOP_VIEW,
  GET_IS_MOBILE_VIEW,
  GET_IS_HAVE_SUGGESTIONS,
  GET_SECONDARY_NAV,
  GET_MYACCOUNT_LINKS,
  GET_DEALS_DATA,
  GET_STORE_INFO,
  GET_STORE_OPERATION_INFO,
  GET_FORMATTED_COUNT,
  GET_IS_MORE_THAN_ONE,
  GET_IS_EMPTY,
  GET_IS_ACTIVE_OVERLAY,
  GET_OVERLAY_NAME,
  GET_COPYRIGHT,
  GET_LEGAL_LINKS,
  GET_SOCIAL_LINKS,
  GET_ZIP_CODE,
  GET_SDD_MESSAGE,
  GET_STORE_NAME,
  GET_SEARCH_MODAL_STATE,
  GET_ATTRS,
  IS_STORE_SELECTED,
  IS_STORES_LOADING,
  SHOW_MINIMAL_SEARCH,
  GET_CREDIT_CARD_DATA,
  GET_SIGNUP_DATA,
  GET_DATA_SOURCE,
};
