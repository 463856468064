<template>
  <div
    class="grid-x align-justify"
    :class="{ 'full-width': isBcom }"
  >
    <LinkRailMenu>
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <Link
          class="link-rail-item"
          :class="{ 'small': isBcom }"
          :href="item.url"
          :tracking="item.tracking"
          @click="legacyTag(item.tracking)"
        >
          {{ item.text }}
        </Link>
      </li>
      <li v-if="isMcom && internationalContextEnabled">
        <InternationalContextLink />
      </li>
    </LinkRailMenu>
    <LinkRailMenu v-if="isBcom && internationalContextEnabled">
      <li>
        <InternationalContextLink />
      </li>
    </LinkRailMenu>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_SECONDARY_NAV } from '../../types/getters';
import { tagRegistryLink, tagStoresLink } from '../../features/analytics/linkRailTags';
import InternationalContextLink from './InternationalContextLink';
import Link from '../common/Link';
import LinkRailMenu from './LinkRailMenu.common';
import useKillswitches from '../../composables/useKillswitches';

const store = useStore();
const killswitches = useKillswitches();
const { isMcom, isBcom } = store.state.envProps;
const items = computed(() => store.getters[`headerStore/${GET_SECONDARY_NAV}`].filter(({ text = '' }) => !text.toLowerCase().includes('shipping to')));
const internationalContextEnabled = computed(() => killswitches.value.internationalContextEnabled);

function legacyTag(tracking) {
  const { cm_sp: trackParam } = tracking;

  if (trackParam.includes('stores')) {
    tagStoresLink();
  } else if (trackParam.includes('registry')) {
    tagRegistryLink();
  }
}
</script>

<style lang="scss"></style>
