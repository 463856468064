<template>
  <div class="wrapper grid-container align-middle align-justify padding-top-xs padding-bottom-xxs">
    <Logo />
    <Search />
    <div class="grid-x align-right">
      <WishList
        v-if="!experiments.accountDropdownEnabled"
        class="margin-right-s"
      />
      <BaseQuickBag class="quick-bag" />
    </div>
  </div>
</template>

<script setup>
import Search from '../search/Search.common';
import WishList from '../common/WishList.common';
import Logo from '../common/Logo.common';
import BaseQuickBag from '../quick-bag/BaseQuickBag.mcom';
import useExperiments from '../../composables/useExperiments';

const experiments = useExperiments();
</script>

<style lang="scss" brand="mcom" scoped>
$search-bar-width-compensator: 12px;

.wrapper {
  display: grid;
  grid-template-columns: 1fr calc(50% - #{$search-bar-width-compensator}) 1fr;
}
.quick-bag {
  position: relative;
  z-index: 2;
}
</style>
