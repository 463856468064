<template>
  <div class="count-badge grid-x align-center align-middle">
    <slot />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.count-badge {
  position: absolute;
  left: 13px;
  top: -4px;
  height: 16px;
  padding: 0 5px;
  color: $white;
  background-color: $highlight-color;
  border-radius: 8px;
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
}
</style>

<style lang="scss" brand="mcom" scoped>
.count-badge {
  min-width: 17px;
}
</style>

<style lang="scss" brand="bcom" scoped>
.count-badge {
  min-width: 16px;
}
</style>
