<template>
  <form
    target="_top"
    name="keywordSearch"
    method="GET"
    :action="formAction"
    @submit.prevent
  >
    <Search
      ref="searchComponent"
      v-model="searchValue"
      name="keyword"
      @onInputClick="openOverlay"
      @keypress="openOverlay"
      @onClear="handleClearSearch"
    />
  </form>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import Search from '@atomic-ui/search';
import { FETCH_SEARCH_SUGGESTIONS, APPLY_SEARCH_TERM } from '../../types/actions';
import { SET_SEARCH_VALUE, SET_SEARCH_MODAL_STATE } from '../../types/mutations';
import { useMobileNav } from '../../composables/useMobileNav';
import { useQueryParams } from '../../composables/dumb/useQueryParams';

const store = useStore();
const { closeNav, isMenuOpened } = useMobileNav();

const query = useQueryParams();

const searchComponent = ref(null);

const formAction = `${store.state.envProps.hostlink}/shop/search`;

const searchValue = computed({
  get() {
    return store.state.headerStore.searchValue;
  },
  set(value) {
    store.commit(`headerStore/${SET_SEARCH_VALUE}`, value);
  },
});

function openOverlay() {
  if (isMenuOpened.value) closeNav();
  store.commit(`headerStore/${SET_SEARCH_MODAL_STATE}`, true);
}

function clearSearchValue() {
  store.dispatch(`navSearchSuggestionsStore/${FETCH_SEARCH_SUGGESTIONS}`, '');
}

function handleClearSearch() {
  clearSearchValue();
  const inputElement = searchComponent.value.$el.querySelector('.input-search');

  if (inputElement) {
    inputElement.focus();
  }
}

function applySearchTerm() {
  store.dispatch(`headerStore/${APPLY_SEARCH_TERM}`);
}

onMounted(() => {
  // business requested to not apply searchValue in search bar if ZOR is true
  if (query?.isZoneOfRelevance === 'true') {
    searchValue.value = '';
  } else {
    applySearchTerm();
  }
});
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/index.scss';

:deep() {
  .input-search:focus {
    outline: none;
  }
  .input-search:focus-within {
    outline: none;
  }
}

:deep(.input-container) {
  margin: 0;
}

@include breakpoint(large up) {
  form {
    position: relative;
    margin-left: auto;
    width: 360px;
    transition: width 0.1s linear;
  }
}
</style>

<style lang="scss" brand="mcom" scoped>
form {
  width: 100%;
}
</style>

<style lang="scss" brand="bcom" scoped>
@import '../../scss/mixins.bcom';

@include breakpoint(large up) {
  form {
    @include margin-right-s;
  }
}
</style>
