<template>
  <Skeleton
    v-if="!userReady"
    height="28px"
    width="28px"
    class="margin-right-xs"
  />
  <button
    v-else-if="isSignedIn"
    class="margin-right-xs"
    data-block-outside-click
    @click="openMenuWithAccountData"
  >
    <i class="icon-28x28 profile" />
  </button>
  <Link
    v-else
    :href="Links.account.signIn.url"
    :tracking="Links.account.signIn.tracking"
    class="link-body mr-12"
    @click="useSignInClick({ tooltipTags: true })"
  >
    Sign In
  </Link>
</template>

<script setup>
import { ref } from 'vue';
import { watchOnce } from '@vueuse/core';
import Skeleton from '@atomic-ui/skeleton';
import Link from '../common/Link';
import Links from '../../constants/Links';
import useAccount from '../../composables/useAccount';
import useSignInClick from '../../composables/useSignInClick';
import { useMobileNav } from '../../composables/useMobileNav';
import { onUserInit } from '../../events';

const userReady = ref(false);
const { isSignedIn } = useAccount();
const {
  openNav, setMode, isMenuOpened, isMenuHydrated,
} = useMobileNav();

function openAccountSubcategory() {
  setMode({ id: 'accountContent', title: '', animate: false }, true);
}

function openMenuWithAccountData() {
  openNav();

  if (isMenuHydrated.value) {
    openAccountSubcategory();
    return;
  }

  watchOnce(isMenuHydrated, () => {
    if (isMenuOpened.value) {
      openAccountSubcategory();
    }
  });
}

onUserInit(() => {
  userReady.value = true;
});
</script>

<style lang="scss">
</style>
