/* istanbul ignore file */
const { mobileTitles } = require('../../config');
const experiments = require('../features/experimentation/config');
const { localKillswitches } = require('../features/killswitches/config');
const { combineKillswitches } = require('../features/killswitches');
const { viewTypes, deviceTypes } = require('../constants');
const transformEditorPopupLink = require('./transformEditorPopupLink');
const { logWarn } = require('./Logger');

function buildHeaderRequestUrl({
  regionCode, currencyCode, deviceType, experimentIds, isMcom, overrideCMParams,
}) {
  const baseParams = [
    '_ccsHeader=true',
    '_removeCmFromUrls=true',
    '_menuFromStella=true',
    '_navigationType=BROWSE',
    '_shoppingMode=SITE',
    `_deviceType=${deviceType}`,
    `_regionCode=${regionCode || 'US'}`,
    `_currencyCode=${currencyCode || 'USD'}`,
  ];
  const enhancedMobileNavExp = experiments.find(({ ks }) => ks === 'enhancedMobileNavEnabled');
  const enhancedMobileNavEnabled = isMcom
    && deviceType === deviceTypes.phone
    && (enhancedMobileNavExp.scaled || experimentIds.includes(enhancedMobileNavExp.treatment));

  if (enhancedMobileNavEnabled) {
    baseParams.push('_getL3Response=true');
  }
  if (overrideCMParams) {
    baseParams.push('_overrideCM=true');
  }
  if (experimentIds.length) {
    baseParams.push(`_customerExperiment=${experimentIds}`);
  }

  return `/xapi/navigate/v1/header?${baseParams.join('&')}`;
}

async function loadHeaderMock(params) {
  logWarn('[navigation] Failed to request header data from headerfooter-xapi, using mock data instead.');
  const { getHeaderFallback } = await require(/* webpackChunkName: "XapiFallback" */ './XapiFallback');
  const data = (await getHeaderFallback(params)).value;

  data.header.meta.properties.enhancedDesktopNavEnabled = false;

  // disable enhanced mobile nav for bcom as we don't have mock data for this experiment yet
  if (params.isMobile && !params.isMcom) {
    data.header.meta.properties.enhancedMobileNavEnabled = false;
  }

  return data;
}

function getExperimentIdForMock({ experimentIds, viewType, isInternational }) {
  // currently we don't have data setup for international
  // TODO: update once we have data setup
  if (isInternational) {
    return null;
  }

  const experimentIdsMap = experimentIds.reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});
  const experiment = experiments.find(({
    treatment, isHeader, mockable, devices,
  }) => mockable && isHeader && experimentIdsMap[treatment] && devices.includes(viewType));

  return experiment ? experiment.treatment : null;
}

function remove(links, textsToRemove) {
  const normalizedTexts = textsToRemove.map((text) => text.toLowerCase());
  return links.filter((link) => !normalizedTexts.includes(link.text?.toLowerCase()?.trim()));
}

function isExperimentActive(experimentIds, killswitches, expName, viewType) {
  const exp = experiments.find((i) => i.ks === expName) || null;

  if (exp) {
    const isExperimentEnabled = exp.scaled || (experimentIds.includes(exp.treatment) && killswitches[expName]);
    const isDeviceMatched = exp.devices.includes(viewType);

    return isExperimentEnabled && isDeviceMatched;
  }

  return false;
}

function getColorFromAttributes(attributes, prop) {
  if (!attributes) {
    return '';
  }

  const defaultColors = ['#000', '#000000'];
  const color = attributes.find(({ name }) => name === prop)?.value;

  if (!color) {
    return '';
  }

  return defaultColors.includes(color) ? '' : color;
}

function getFobColumnGroupData({ contentLinks: { value, text, semanticUrl, tracking } }, index, idPrefix, linkColor) { // eslint-disable-line
  return {
    text,
    tracking,
    id: `${idPrefix}_${index}`, // we cannot use value as an ID because value can contain url instead of ID, and url can be not unique
    url: semanticUrl || value,
    color: linkColor,
  };
}

function getFobColumnData({ contents }, groupIndex, idPrefix) {
  return contents.map((column, index) => {
    const { flexibleLinks, navigationFlyoutAd } = column;
    const { attributes } = flexibleLinks || {};
    const subCategoryColor = getColorFromAttributes(attributes, 'categoryFontColor');
    const linkColor = getColorFromAttributes(attributes, 'subCategoryFontColor');
    const newIdPrefix = `${idPrefix}_${groupIndex}_${index}`;

    if (navigationFlyoutAd) {
      const adLink = navigationFlyoutAd.contentlinks?.[0];
      const imageLink = navigationFlyoutAd.image?.contentlinks?.[0];
      const url = adLink?.semanticUrl || adLink?.value || '';

      return {
        url,
        isImage: true,
        src: `${navigationFlyoutAd.urlTemplate}${navigationFlyoutAd.urlTemplate.endsWith('/') ? '' : '/'}${navigationFlyoutAd.image.fileName}`,
        imageLink: imageLink?.semanticUrl || imageLink?.value || '',
        imageLinkTracking: imageLink?.tracking || null,
        text: adLink?.text || '',
        alt: navigationFlyoutAd.body || adLink?.text,
        linkTracking: adLink?.tracking || null,
      };
    }

    const groupLink = flexibleLinks.category?.[0]?.contentLinks || {};

    groupLink.text = groupLink.text || '';
    groupLink.id = groupLink.value || newIdPrefix;

    const url = groupLink.semanticUrl || groupLink.value || '';

    return {
      url,
      id: groupLink.id,
      text: groupLink.text,
      tracking: url ? groupLink.tracking : null,
      color: subCategoryColor,
      children: flexibleLinks.subCategory.map((item, i) => getFobColumnGroupData(item, i, newIdPrefix, linkColor)),
    };
  });
}

function menuAdapter(data, isMobile) {
  if (!data.header.fobs) return [];
  return data.header.fobs.globalNavigation.reduce((acc, fob, index) => {
    const idPrefix = index.toString();
    const { contentlinks, attributes, flyout = {} } = fob;
    const mainLink = contentlinks?.[0];

    if (!mainLink) {
      return acc;
    }

    const { container } = flyout;
    const color = getColorFromAttributes(attributes, 'fontColor');
    let children;

    if (container) {
      children = container.map((item, i) => getFobColumnData(item, i, idPrefix));
    } else {
      children = [];
    }

    const mainLinkUrl = mainLink.semanticUrl || mainLink.value || '';

    if (!mainLinkUrl && !children.length) {
      return acc;
    }

    mainLink.id = mainLink.value || idPrefix;

    const category = {
      id: mainLink.id,
      tracking: mainLink.tracking,
      text: mainLink.text,
      url: mainLinkUrl,
      children,
    };

    if (!isMobile) {
      category.color = color;
    }

    acc.push(category);

    return acc;
  }, []);
}

function getMcomMobileTopCategoryIds(properties, isExperiment) {
  if (isExperiment) {
    const ids = [];

    properties.menuLabels?.forEach((label) => {
      ids.push(...properties[label]);
    });

    return ids;
  }

  return properties.topMenu?.split(',') || [];
}

function transformBcomMobileMenu(map, categories, parentId) {
  categories.forEach(({
    id, text, url, fontColor, tracking, children,
  }, index) => {
    if (map[id]) {
      return;
    }

    const normalizedChildren = (children?.[0]?.group || children || []).reduce((acc, link) => {
      const subLinks = link.children?.[0]?.group;

      if (subLinks) {
        transformBcomMobileMenu(map, [link], id);
      } else if (!link.url) {
        return acc;
      }

      acc.push({
        id: link.id,
        text: link.text,
        url: link.url || '',
        tracking: link.tracking || null,
        isCatSplash: false,
      });

      return acc;
    }, []);

    const itemData = {
      id,
      text,
      tracking: tracking || null,
      url: url || '',
      children: normalizedChildren,
      isCatSplash: false,
      ...(fontColor ? { fontColor } : {}),
    };

    const isTopLevel = !parentId;

    if (isTopLevel) {
      itemData.order = index;
    }

    if (parentId) {
      itemData.parentId = parentId;
    }

    map[id] = itemData;
  });

  return map;
}

function parseMcomMobileMenu(menu, topMenuIds) {
  const menuObj = menu || {};
  const topMenuIdsMap = topMenuIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});
  return Object.values(menuObj).reduce((acc, {
    id, text, tracking, url, parent, children, categoryPageType,
  }) => {
    if (topMenuIdsMap[id]) {
      if (!url && !children?.length) {
        return acc;
      }
    } else if (!children?.length) {
      return acc;
    }

    acc[id] = {
      id,
      text,
      tracking: tracking || null,
      url: url || '',
      isCatSplash: Boolean(categoryPageType === 'Category Splash' && children?.length),
      children: (children || []).reduce((childrenAcc, link) => {
        if (!link.url && !menuObj[link.id]?.children?.length) {
          return childrenAcc;
        }

        childrenAcc.push({
          id: link.id,
          text: link.text,
          url: link.url || '',
          tracking: link.tracking || null,
          isCatSplash: Boolean(link.categoryPageType === 'Category Splash' && menu[link.id]?.children?.length),
        });

        return childrenAcc;
      }, []),
    };

    if (parent?.id) {
      acc[id].parentId = parent.id;
    }

    return acc;
  }, {});
}

function parseAndReduceMobileMenu(menu, properties, isMcom, isExperiment) {
  const reducedMobileMenu = {};
  const categoryLevels = {};
  let topCategories;
  let mobileMenu;

  if (isMcom) {
    topCategories = getMcomMobileTopCategoryIds(properties, isExperiment);
    mobileMenu = parseMcomMobileMenu(menu || {}, topCategories);
  } else {
    topCategories = isExperiment ? getMcomMobileTopCategoryIds(properties, isExperiment) : menu.map(({ id }) => id);
    mobileMenu = transformBcomMobileMenu({}, menu || []);
  }

  if (!isExperiment) {
    // remove empty links
    Object.values(mobileMenu).forEach((category) => {
      if (category?.children?.length) {
        category.children = category.children.filter((link) => {
          if (link.url) {
            return true;
          }

          const relatedCategory = mobileMenu[link.id];

          return relatedCategory && (relatedCategory.url || relatedCategory.children?.length);
        });
      }
    });

    return mobileMenu;
  }

  topCategories.forEach((id) => {
    const category = mobileMenu[id];

    if (!category) {
      return;
    }

    reducedMobileMenu[id] = category;
    (categoryLevels[id] || (categoryLevels[id] = {})).l1 = true;
    category?.children?.forEach(({ id: subCategoryId }) => {
      const subCategory = mobileMenu[subCategoryId];

      if (subCategory) {
        reducedMobileMenu[subCategoryId] = subCategory;
        (categoryLevels[subCategoryId] || (categoryLevels[subCategoryId] = {})).l2 = true;

        subCategory.children?.forEach(({ id: l3SubCategoryId, url: l3Url }) => {
          // save L3 categories to show L4 only if L3 is a browse-hide category
          // browse-hide category is a category without url property
          if (!l3Url) {
            const l3SubCategory = mobileMenu[l3SubCategoryId];

            if (l3SubCategory?.children?.length) {
              reducedMobileMenu[l3SubCategoryId] = l3SubCategory;
              (categoryLevels[l3SubCategoryId] || (categoryLevels[l3SubCategoryId] = {})).l3 = true;
            }
          }
        });
      }
    });
  });

  // remove redundant empty links from children
  Object.values(reducedMobileMenu).forEach((category) => {
    if (category?.children?.length) {
      category.children = category
        .children
        .filter((link) => link.url
        || (
          (categoryLevels[link.id]?.l1 || categoryLevels[link.id]?.l2 || categoryLevels[link.id]?.l3)
            // this condition is needed to prevent hydration issues as we may receive categories like these:
            // {
            //  "id": "1242628",
            //  "text": "Fall Trends",
            //  "children": [
            //    { "group": [
            //      { "id": "1238674", "text": "Shop All" },
            //      { "id": "1238670", "text": "70's Sophistication" },
            //      { "id": "1238669", "text": "City Chic" },
            //      { "id": "1238671", "text": "Animal Print" }
            //  ],
            // }
            // in this example we don't have category url and children urls
            // we will reduce it to the next structure:
            // {
            //  "id": "1242628",
            //  "text": "Fall Trends",
            //  "children": []
            // }
            // so we need to filter out any references to the categories like this from another categories
            && (reducedMobileMenu[link.id]?.url || reducedMobileMenu[link.id]?.children?.length)
        ),
        []);
    }
  });

  return reducedMobileMenu;
}

// todo: work with AEM to delete redundant links
function fixSiteServiceGlobalPool(data) {
  const initialLinks = data.secondaryNavigation;
  const linksToRemove = ['Wedding Registry', 'Gift Registry', 'The Registry', 'bRegistry', 'Shipping To'];

  data.secondaryNavigation = [...remove(initialLinks, linksToRemove)];
}

function fixMyAccountLinks(links, viewType) {
  const isDesktop = viewType === viewTypes.desktop;
  const normalizedLinks = links.map(({ value: url, text, tracking }) => ({ url, text, tracking }));

  if (isDesktop) {
    return remove(normalizedLinks, ['star rewards']);
  }

  return normalizedLinks.map((link) => (link.text.toLowerCase() === 'loyallist' ? { ...link, id: 'LOYALLIST' } : link));
}

function normalizeHeaderData({
  source, viewType, isMcom, experimentIds = [], isAemMenu, killswitchesOverride,
}) {
  const data = { ...source };
  const isMobile = viewType === viewTypes.mobile;
  const accountLinks = remove(data.header.signInLinks?.contentlinks || [], ['Sign In']);
  const killswitches = data.header.meta.properties;
  const enhancedMobileNavExpEnabled = isExperimentActive(experimentIds, killswitches, 'enhancedMobileNavEnabled', viewType);

  // TODO (Refactor): remove redundant nesting
  data.media = {
    SITE_MYACCOUNT_MENU: {
      name: 'SITE_MYACCOUNT_MENU',
      items: [{
        children: fixMyAccountLinks(accountLinks, viewType),
      }],
    },
  };

  if (isMcom) {
    data.topNavigation = data.header.topNavigation;
    data.skinnyDealsBanner = data.header.skinnyDealsBanner;
  } else {
    data.topNavigation = data.header.topNavigation?.map(({ text }) => ({
      text: text && transformEditorPopupLink(text),
    }));
  }
  data.meta = { ...data.header.meta };
  data.secondaryNavigation = data.header.secondaryNavigation?.contentlinks.map(({ text, value: url, tracking }) => ({ text, url, tracking })) || [];

  if (isAemMenu) {
    data.desktopMenu = menuAdapter(data, isMobile);
  } else if (isMobile) {
    const { topMenu, menuLabels } = killswitches;

    if (enhancedMobileNavExpEnabled && !menuLabels?.length) {
      killswitches.menuLabels = [mobileTitles.shopByDepartment];

      if (isMcom) {
        killswitches[mobileTitles.shopByDepartment] = topMenu.split(',');
      } else {
        killswitches[mobileTitles.shopByDepartment] = data.header.menu.map(({ id }) => id);
      }
    }

    data.menu = parseAndReduceMobileMenu(data.header.menu, data.header.meta.properties, isMcom, enhancedMobileNavExpEnabled);
  }

  if (isMobile) {
    fixSiteServiceGlobalPool(data);
  }

  const filteredKillswitches = combineKillswitches({ killswitches, localKillswitches, killswitchesOverride });

  if (isMobile) {
    const { topMenu, menuLabels = [] } = killswitches;
    const topGroupsMenu = menuLabels.reduce((acc, key) => acc.concat({
      title: key,
      // filter out empty static links to avoid hydration issues
      ids: killswitches[key].filter((id) => {
        const link = data.menu?.[id];
        return link && (link.url || link.children?.length);
      }),
    }), []);
    data.meta.properties = { topMenu, topGroupsMenu };
  } else {
    data.meta.properties = {};
  }

  delete data.header;

  return { data, filteredKillswitches };
}

module.exports = {
  buildHeaderRequestUrl,
  loadHeaderMock,
  remove,
  normalizeHeaderData,
  getExperimentIdForMock,
  // for tests
  isExperimentActive,
  getColorFromAttributes,
  getFobColumnGroupData,
  getFobColumnData,
  menuAdapter,
  getMcomMobileTopCategoryIds,
  transformBcomMobileMenu,
  parseMcomMobileMenu,
  parseAndReduceMobileMenu,
  fixSiteServiceGlobalPool,
  fixMyAccountLinks,
};
