<template>
  <div class="grid-x align-justify">
    <div class="grid-x align-middle">
      <div class="grid-x margin-right-xs padding-left-z">
        <MenuButton />
      </div>

      <Logo />
    </div>

    <div class="grid-x align-middle">
      <WishList
        v-if="isSignedIn && !experiments.accountDropdownEnabled"
        class="margin-right-xs"
      />
      <SignInLink
        v-else-if="isDomestic && !isSignedIn"
        v-show="mounted"
        class="mew-sign-in-btn margin-right-xs"
        :class="[{'primary': isMcom}, {'secondary': isBcom}]"
      />
      <BagIcon />
    </div>
  </div>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useMounted } from '@vueuse/core';

import Logo from '../common/Logo.common';
import MenuButton from './MenuButton.common';
import BagIcon from '../common/BagIcon.common';
import WishList from '../common/WishList.common';
import SignInLink from '../common/SignInLink';
import useExperiments from '../../composables/useExperiments';

const store = useStore();
const { isMcom, isBcom } = store.state.envProps;
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
const mounted = useMounted();
const experiments = useExperiments();
</script>

<style lang="scss" scoped brand="bcom">
.mew-sign-in-btn {
  padding: 5px 10px;
}
</style>

<style lang="scss" scoped brand="mcom">
.mew-sign-in-btn {
  padding: 6px 9px;
}
</style>
