const { getSiteMode } = require('./src/utils/getSiteMode');

const FRICTION_FREE_CATEGORY = 320264;
const ENTRY_POINT = '0000';
const LEARN_MORE_PARAM = '_learnMore';
const BOPS_ENTRY_POINT_URL = `/shop/pickup-delivery/Upc_bops_purchasable/${ENTRY_POINT}?id=${FRICTION_FREE_CATEGORY}`;

module.exports = {
  brand: 'mcom',
  mobileTitles: {
    happeningNow: 'Happening Now',
    shopByDepartment: 'Shop by Department',
    shopByStore: 'Shop Your Store',
    moreToExplore: 'More to Explore',
  },
  appLinks: [
    {
      title: 'App Store',
      link: 'https://itunes.apple.com/US/app/id341036067?mt=8',
      img: 'appStore.svg',
    },
    {
      title: 'Google Play',
      link: 'https://play.google.com/store/apps/details?id=com.macys.android&hl=en_US',
      img: 'googlePlay.svg',
    },
  ],
  getFlagUrl: (regionCode, getAssetImage) => getAssetImage(`flags/${regionCode}.png`),
  Links: {
    homepage: {
      site: {
        url: '/',
        tracking: {
          lid: (context) => `glbtopnav_macys_icon-${getSiteMode(context)}`,
          link_name: 'glbtopnav_macys_icon-us',
        },
      },
    },
    bag: {
      url: '/my/bag',
      tracking: {
        cm_sp: 'navigation-_-top_nav-_-bag',
        lid: (context) => `glbtopnav_bag-${getSiteMode(context)}`,
        link_name: 'glbtopnav_bag-us',
        page_name: 'shopping bag',
        page_type: 'shopping bag',
      },
    },
    wishlist: {
      url: '/wishlist/home?pageSource=nav',
      tracking: {
        cm_sp: 'navigation-_-top_nav-_-lists',
        lid: (context) => `glbtopnav_lists-${getSiteMode(context)}`,
        link_name: 'glbtopnav_lists-us',
        page_name: 'wishlist home',
        page_type: 'wishlist',
      },
    },
    store: {
      changeStore: {
        text: 'Change Store',
      },
      pickupAndDelivery: {
        text: 'Pickup & Delivery',
      },
      freeStorePickup: {
        url: BOPS_ENTRY_POINT_URL,
        text: 'Shop Free Store Pickup',
        pos: 2,
        tracking: {
          cm_sp: 'navigation-_-top_nav-_-shop-free-store-pickup',
        },
      },
      sameDayDelivery: {
        url: `/shop/pickup-delivery/Upc_sdd_purchasable/${ENTRY_POINT}?id=${FRICTION_FREE_CATEGORY}`,
        text: 'Shop Same-Day Delivery',
        pos: 3,
        tracking: {
          cm_sp: 'navigation-_-top_nav-_-shop-same-day-delivery',
        },
      },
      nextDayDelivery: {
        url: `/shop/pickup-delivery/Upc_sdd_purchasable/${ENTRY_POINT}?id=${FRICTION_FREE_CATEGORY}`,
        text: 'Shop Next-Day Delivery',
        pos: 3,
        tracking: {
          cm_sp: 'navigation-_-top_nav-_-shop-next-day-delivery',
        },
      },
      learnMore: {
        url: `${BOPS_ENTRY_POINT_URL}&${LEARN_MORE_PARAM}=true`,
        text: 'Learn more',
        pos: 4,
        tracking: {
          cm_sp: 'navigation-_-top_nav-_-learn-more',
        },
      },
    },
  },
  minimal: {
    links: {
      default: [
        {
          name: 'Privacy Notice',
          url: 'https://customerservice-macys.com/articles/highlights-of-macys-inc-notice-of-privacy-practices',
        },
        {
          name: 'Legal Notice',
          url: 'https://customerservice-macys.com/articles/legal-notice',
        },
        {
          name: 'Interest Based Ads',
          url: 'https://customerservice-macys.com/articles/macys-and-macyscom-notice-of-privacy-practices#interest-based-or-online-behavioral-advertising',
        },
      ],
      checkout: [
        {
          name: 'Privacy Policy',
          url: 'https://customerservice-macys.com/articles/highlights-of-macys-inc-notice-of-privacy-practices',
        },
        {
          name: 'Legal Notice',
          url: 'https://customerservice-macys.com/articles/legal-notice',
        },
        {
          name: 'Interest Based Ads',
          url: 'https://customerservice-macys.com/articles/macys-and-macyscom-notice-of-privacy-practices#interest-based-or-online-behavioral-advertising',
        },
        {
          name: 'Shipping Policy',
          url: 'https://www.customerservice-macys.com/category/shipping-delivery',
          tracking: {
            cm_sp: 'navigation-_-bottom_nav-_-shipping_%26_delivery',
            lid: 'glbbtmnav_shipping_%26_delivery-us',
          },
        },
        {
          name: 'Return Policy',
          url: 'https://www.customerservice-macys.com/category/returns',
          tracking: {
            cm_sp: 'navigation-_-bottom_nav-_-returns',
            lid: 'glbbtmnav_returns-us',
          },
        },
      ],
    },
    copyright: [
      `© ${new Date().getFullYear()} Macy’s. All rights reserved. Macys.com, LLC,`,
      '151 West 34th Street, New York, NY 10001',
    ],
  },
  logoLabel: {
    site: 'Macy\'s Home Page',
  },
  defaultStoreName: 'Find a Store',
  registryLinkGroups: [
    {
      title: null,
      items: [
        {
          text: 'Find a registry',
          url: '/registry/search',
        },
        {
          text: 'Create a registry',
          url: '/registry',
        },
        {
          text: 'Wedding checklist',
          url: '/s/registry-guide/checklist/',
        },
        {
          text: 'Baby checklist',
          url: '/s/baby-registry/checklist/',
        },
        {
          text: 'FAQs',
          url: 'https://customerservice-macys.com/category/wedding-registry-gifts',
        },
      ],
    },
  ],
};
